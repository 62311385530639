@tailwind base;
@tailwind components;
@tailwind utilities;

.react-dnd-clone ~ [data-rbd-placeholder-context-id] {
  display: none !important;
}

.react-dnd-clone ~ div {
  transform: none !important;
}
